import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import BluredCircle from "./BluredCircle";
import Icon from "@/components/ui/Icon";

import Space from "@/components/ui/Space";
import { categorySlugs } from "@/constants/categories";

import categoryImageArt from "@/public/static/category-images/art-2.webp";
import categoryImageMusic from "@/public/static/category-images/music-2.webp";
import categoryImageFarsi from "@/public/static/category-images/farsi.webp";
import categoryImageSport from "@/public/static/category-images/sport.webp";
import categoryImageScience from "@/public/static/category-images/science.webp";
import categoryImageLanguage from "@/public/static/category-images/languages.webp";
import categoryImageProgramming from "@/public/static/category-images/programming.webp";
import categoryImageChess from "@/public/static/category-images/chess.webp";
import categoryImageApplications from "@/public/static/category-images/applications.webp";
import categoryImagePersonalDevelopment from "@/public/static/category-images/personal-development.webp";
import LinkButton from "../ui/button/LinkButton";
import { En, Fa } from "../ui/multilang";
import { sendGTMEvent } from "@next/third-parties/google";

const categories = [
  {
    id: 2,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Music",
    nameFa: "موسیقی",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/music-2.webp",
    image: categoryImageMusic,
    gradientTo: "to-brand-warm-black",
    icon: "bf-i-ph-music-note",
  },
  {
    id: 5,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Language",
    nameFa: "زبان",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/languages.webp",
    image: categoryImageLanguage,

    gradientTo: "to-brand-pink",
    icon: "bf-i-ph-translate",
  },

  {
    id: 4,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Farsi",
    nameFa: "فارسی",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/farsi.webp",
    image: categoryImageFarsi,
    gradientTo: "to-brand-coral",
    icon: "bf-i-ph-book-open-text",
  },
  {
    id: 8,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Chess",
    nameFa: "شطرنج",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/chess.webp",
    image: categoryImageChess,
    gradientTo: "to-brand-blue",
    icon: "bf-i-ph-horse",
  },
  {
    id: 7,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Science and Math",
    nameFa: "ریاضی و علوم",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/science.webp",
    image: categoryImageScience,
    gradientTo: "to-brand-green",
    icon: "bf-i-ph-atom",
  },
  {
    id: 6,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Sport",
    nameFa: "ورزش",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    image: categoryImageSport,
    imageSrc: "/static/category-images/sport.webp",
    gradientTo: "to-brand-orange",
    icon: "bf-i-ph-football",
  },

  {
    id: 3,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Programming",
    nameFa: "برنامه‌نویسی ",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/programming.webp",
    image: categoryImageProgramming,
    gradientTo: "to-brand-amber",
    icon: "bf-i-ph-code-bold",
  },

  {
    id: 1,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Art",
    nameFa: "هنر",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/music.webp",
    image: categoryImageArt,
    gradientTo: "to-brand-brown",
    icon: "bf-i-ph-palette",
  },
  {
    id: 39,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Applications",
    nameFa: "برنامه‌های کاربردی",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/music.webp",
    image: categoryImageApplications,
    gradientTo: "to-brand-brown",
    icon: "bf-i-ph-app-window",
  },
  {
    id: 56,
    created_at: "2023-12-09T17:26:05.277829+00:00",
    name: "Personal Development",
    nameFa: "توسعه فردی",
    parentId: null,
    level: 1,
    bg: "bg-sand9",
    imageSrc: "/static/category-images/music.webp",
    image: categoryImagePersonalDevelopment,
    gradientTo: "to-brand-brown",
    icon: "bf-i-ph-flower-lotus",
  },
];

export default function Categoreis() {
  const { locale } = useRouter();

  return (
    <section className="relative max-w-70rem mx-auto">
      <BluredCircle radius={200} top="20%" left="95%" bg="bg-brand-light-amber" blur="200px" opacity={0.3} />
      <BluredCircle radius={200} top="70%" left="0%" bg="bg-green9" blur="200px" opacity={0.06} />

      <div className="">
        <h2 className="H2 c-title">{locale === "fa" ? "کلاس‌ها" : "Subjects"}</h2>
        <Space size="h-4" />
        <ul
          className={` grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4
        lt-sm:grid-flow-row-dense
          `}
          style={{ gridTemplateRows: "repeat(16 , auto)" }}
        >
          {categories.map((cat) => {
            return <CategoryCard key={`category-card-${cat.id}`} category={cat} />;
          })}
          <li
            className={` md:g-row-9/10 md:g-col-4/5
            sm:g-row-8/8 sm:g-col-3/4
            grid-row-span-1 g-col-2/3 shd-tinted-3
          `}
          >
            <LinkButton
              width="parent"
              variation="solid"
              href="/subjects"
              onClick={() => sendGTMEvent({ event: "category-cta-home-button-click" })}
              className={`!py-2em !rd-3 flex justify-between items-center subject-listing-cta-button
           `}
            >
              <En>View All Subjects</En>
              <Fa>همه‌ی کلاس‌ها را ببینید</Fa>
              <En>
                <Icon name="bf-i-ph-arrow-right" className="mis-2 text-2xl" subdued={false} />
              </En>
              <Fa>
                <Icon name="bf-i-ph-arrow-left" className="mis-2 text-2xl" subdued={false} />
              </Fa>
            </LinkButton>
          </li>
        </ul>
      </div>
    </section>
  );
}

const gridPosition = {
  music: `
  md:g-row-1/4 md:g-col-1/2
  sm:g-row-2/4 sm:g-col-1/2
  g-row-3/5 g-col-1/2

  `,
  language: `
    md:g-row-5/9 md:g-col-2/3
    sm:g-row-4/6 sm:g-col-3/4
    grid-row-span-5 g-col-2/3

  `,
  farsi: `
    md:g-row-4/6 md:g-col-3/4
    sm:g-row-2/4 sm:g-col-3/4
    grid-row-span-4 g-col-1/2
  `,
  chess: `
    md:g-row-6/13 md:g-col-3/4
    sm:g-row-6/8 sm:g-col-1/2
    grid-row-span-9 g-col-1/2


  `,
  "science and math": `
    md:g-row-4/6 md:g-col-1/2
    sm:g-row-5/7 sm:g-col-2/3
    grid-row-span-2 g-col-1/2
  `,
  sport: `
    md:g-row-2/5 md:g-col-2/3
    sm:g-row-3/5 sm:g-col-2/3
    grid-row-span-6 g-col-2/3
  `,
  programming: `
    md:g-row-3/5 md:g-col-4/5
    sm:g-row-4/6 sm:g-col-1/2
    grid-row-span-5 g-col-2/3
  `,
  art: `
    md:g-row-5/9 md:g-col-4/5
    sm:g-row-7/10 sm:g-col-2/3
    grid-row-span-2 g-col-2/3`,
  applications: `
    md:g-row-1/4 md:g-col-3/4
    sm:g-row-1/3 sm:g-col-2/3
    grid-row-span-4 g-col-1/2`,
  "personal development": `
    md:g-row-6/10 md:g-col-1/2
    sm:g-row-6/8 sm:g-col-3/4
    grid-row-span-2 g-col-2/3`,
};

function CategoryCard({ category }) {
  const { locale } = useRouter();

  const categorySlug = category.id ? categorySlugs[category.id] : "";

  return (
    <li key={category.id} className={`${gridPosition[category.name.toLowerCase()]} `}>
      <Link
        href={`/${categorySlug}`}
        className={` rd-xl overflow-hidden  shd-tinted-4 ${category.bg} h-full grid`}
        style={{
          gridTemplateColumns: "1fr",
          gridTemplateRows: "1fr auto",
          // gridRow: `${categoryGridRow[category.name.toLowerCase()]}`,
          // gridColumn: `${categoryGridColumn[category.name.toLowerCase()]}`
        }}
      >
        <Image
          src={category.image}
          width={500}
          height={300}
          alt={locale === "fa" ? category.nameFa : category.name}
          className="w-full h-full  sm:h-full object-cover"
          style={{ gridArea: "1/1/-1/1" }}
          priority
        />
        <GradientOverlay
          gradient={`bg-gradient-to-b
          from-brand-orange/25
          //to-brand-orange/90
          ${categories.find((cat) => cat.name.toLowerCase() === category.name.toLowerCase())?.gradientTo}
          `}
        />
        <div className="flex justify-between items-end p-4 " style={{ gridArea: "2/1/-1/-1" }}>
          <h3 className="font-display c-melow text-xl sm:text-2xl tracking-tight c-white">
            <div>
              <Icon name={category.icon} subdued={false} className="text-2xl" />
              <div className="h-1"></div>
            </div>
            <span className="!fw-600">{locale === "fa" ? category.nameFa : category.name}</span>
          </h3>{" "}
          <span className="c-white  whitespace-nowrap item-end">
            <span className="sr-only">{locale === "fa" ? "مشاهده" : "Explore"}</span>
            <span
              className={`before:opacity-100 text-xl ${
                locale === "fa" ? "bf-i-ph-caret-left-bold mie-1" : "bf-i-ph-caret-right-bold mis-1"
              }`}
            />
          </span>
        </div>
      </Link>
    </li>
  );
}

function GradientOverlay({ gradient }) {
  return <div className={`${gradient} `} style={{ gridArea: "1/1/-1/-1" }}></div>;
}
